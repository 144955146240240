

    $(document).on('input', 'input[type="range"]', function(e) {
        valueOutput(e.target);
    });

    var rangeInputs = $('input[type="range"]');
    var sliders = [];
    for (var i = 0; i < rangeInputs.length; i++) {
        sliders[ rangeInputs[i].name ] = $(rangeInputs[i]).rangeslider({
            polyfill: false,

            onSlideEnd: function(position, value) {
                setLevel( $('#'+this.identifier).prev().attr('data-device'), value);
            }
        });
    }


    $('body').on('click', '[data-toggle="modal"]', function(){
        $($(this).data("target")+' .modal-body').html('<div id="loading"></div>');
        $($(this).data("target")+' .modal-body').load($(this).data("remote"));
    });

    // Fetch initial levels and insert them in percent indicators
    getLevel();



var highchartsOptions = {
    chart: {
        height: 150,
        type: 'spline',
        zoomType: 'x'
    },
    title: {
        text: null
    },
    subtitle: {
        text: null
    },
    plotOptions: {
        series: {
            lineWidth: 1,
            marker: {
                enabled: false
            }
        }
    },
    tooltip: {
        xDateFormat: '%e %b %Y<br>%l:%M %p'
    },
    legend: {
        enabled: false
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        labels: {
            enabled: false
        },
        title: {
            text: null
        }
    },
    colors: ['#424242']
};

