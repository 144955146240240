function valueOutput(element) {
    var value = element.value,
        output = document.getElementById( element.getAttribute('data-output') );
    output.innerHTML = value;
}

function initValues(data) {
    for(var key in data){
        if( sliders[key] != 'undefined' && $('#percent-'+key).hasClass('init') ) {
            sliders[key].val(data[key]).change();
            $('#percent-'+key).html(data[key]).removeClass('init');
        }
    }
}

function getLevel() {
    $('.percent').addClass('init');
    $.ajax(
        'level/get',
        {
            success: function(a,b,c){
                initValues(c.responseJSON);
            },
            complete: function(jqXHR, textStatus) {
                var getLevelTimeout = setTimeout(getLevel, 60000);
            }
        }
    )
}

function setLevel(device, level) {
    $('#percent-'+device).removeClass('init').addClass('pending')
    $.ajax(
        'level/set',
        {
            data: {
                'device': device,
                'level': level
            },
            method: 'POST',
            success: function(){
                $('#percent-'+device).removeClass('pending');
                $('#percent-'+device).addClass('success');
                setTimeout(
                    function(){
                        $('#percent-'+device).removeClass('success');
                    },
                    5000
                );
            }
        }
    );
}

function extend(obj, src) {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
